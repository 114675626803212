var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "app-row-content pb-row" }, [
      !_vm.state.isMobile
        ? _c(
            "div",
            {
              class: _vm.state.isExpanded
                ? " pb-col-sm-4 pb-col-md-2 pb-col-lg-3 pb-col-xl-3 pb-col-xxl-2"
                : " pb-col-sm-3 pb-col-md-1 pb-col-lg-2 pb-col-xl-1 pb-col-xxl-1",
            },
            [
              _c("WorkflowSideMenu", {
                on: { "toggle-side-menu": _vm.toggleSideMenu },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.state.isExpanded
            ? "pb-col-lg-10 pb-col-xl-9 pb-col-xxl-10"
            : "pb-col-lg-10 pb-col-xl-10 pb-col-xxl-11",
        },
        [
          _c("div", { staticClass: "pb-row" }, [
            !_vm.state.isMobile
              ? _c(
                  "div",
                  { staticClass: "app-navbar-content pb-col-12" },
                  [_c("Header")],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "app-navbar-content-mobile pb-col-12",
                    staticStyle: { display: "flex", "justify-content": "end" },
                  },
                  [_c("Drawer")],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "main pb-row app-content" }, [
            _c(
              "div",
              { staticClass: "pb-col-12" },
              [
                _c("PbToastNotification", {
                  ref: "toastNotification",
                  attrs: {
                    backgroundColor: _vm.state.toastNotification.color,
                    message: _vm.state.toastNotification.message,
                    duration: 5000,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "app-main-content" }, [
                  !_vm.state.loading
                    ? _c(
                        "div",
                        { staticClass: "router-wrapper" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade" } },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.loading
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "-webkit-center",
                          },
                        },
                        [
                          _c("PbPulseLoading", {
                            attrs: {
                              color: "gray-80",
                              size: "80px",
                              borderWidth: 20,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pb-row" }, [
      _c("div", { staticClass: "footer pb-row" }, [
        _c("div", { staticClass: "pb-col-12" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }