<template>
  <div class="app-header">
    <div class="title-page">
      <PbButton
        v-if="isReturnable"
        color="primary"
        buttonStyle="regular"
        icon="fas fa-arrow-up fa-rotate-270"
        class="button-back"
        @click.native="goBack"
      ></PbButton>
      <h2 class="pb app-header-color">{{ pageName }}{{ getLastSubPageId }}</h2>
    </div>
    <div class="icon-back-mobile" v-show="!state.isMobile">
      <Notification @openNotification="state.notification.route" />

      <PbDropdown color="primary" ref="dropdown">
        <template #header>
          <div class="user-dropdown-actions">
            <div class="user-avatar">
              <p class="pb">
                {{ getInitialsName }}
              </p>
            </div>

            <div
              v-if="!state.isMobile"
              class="user-name"
              :title="state.user.userName"
            >
              <p class="pb disable-select">
                {{ state.user.userName }}
              </p>
            </div>
          </div>
        </template>

        <div
          v-for="route in state.accessData"
          :key="route.path"
          class="app-action-buttons"
        >
          <PbButton
            v-if="route.name !== 'Cadastro de Promoções'"
            :to="route.path"
            :disabled="!!route.disabled"
            color="white"
            buttonStyle="background"
            :label="route.name"
            @click.native="goToRoute(route.path)"
          >
          </PbButton>
        </div>
      </PbDropdown>
    </div>
  </div>
</template>

<script>
import { PbDropdown, PbButton } from 'vue-polar-bear';
import EventBus from '../plugins/event-bus';
import { hasAccessToPage } from '../utils/utils';
import Notification from './Notification/Notification.vue';

export default {
  name: 'NavBar',

  components: {
    PbDropdown,
    PbButton,
    Notification,
  },

  data() {
    return {
      state: {
        isMobile: window.innerWidth <= 767,
        showHeader: true,
        notification: {
          route: '',
        },
        user: {
          userName: '',
        },
        accessData: [],
      },
    };
  },

  mounted() {
    const { userInfo } = this.$store.getters;

    this.state.user.userName =
      typeof userInfo === 'object' && userInfo !== null ? userInfo.name : '';

    window.addEventListener('resize', this.getWidthSize);

    this.getAccessData();

    EventBus.$on('changeUserOptions', (hasFullAccess) => {
      this.state.user.hasTotalAccess = !!hasFullAccess;

      this.getAccessData();
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWidthSize);
  },

  computed: {
    isReturnable() {
      if (Object.keys(this.$route.params).length >= 1) {
        return true;
      }
      return false;
    },

    getLastSubPageId() {
      if (this.isReturnable === false) return '';

      const routePath = this.$route.path.split('/');
      const lastPath = routePath.length - 1;

      return ` - ${routePath[lastPath]}`;
    },

    pageName() {
      if (this.$route.name === 'Home') return '';

      return this.$route.name;
    },

    getInitialsName() {
      let nameList = [];
      let nameInitials = '';
      let limit = 2;
      let count = 0;

      nameList = this.state.user.userName.split(' ');

      nameList.forEach((name) => {
        if (count >= limit) return;
        nameInitials = `${nameInitials}${name.split('')[0]}`;
        count++;
      });
      return nameInitials;
    },
  },

  methods: {
    hasAccessToPage,

    getAccessData() {
      this.state.accessData = [];

      this.$router.options.routes.forEach((route) => {
        if (route.path.includes(':id') || route.path === '/workflow')
          return false;

        route.disabled = !this.hasAccessToPage(route.path);

        this.state.accessData.push(route);
      });
    },

    goToRoute(route) {
      if (this.$router.currentRoute.path === route) return;
      this.$store.dispatch('reloadSalesOrders', true);
      this.$store.dispatch('defineRequestState', {});
      this.$router.push(route);
    },

    getWidthSize(e) {
      const { target } = e;

      this.state.isMobile = target.innerWidth <= 767;
    },

    goBack() {
      this.$router.push(
        this.$route.path.replaceAll(`/${this.$route.params.id}`, ''),
      );
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  justify-content: space-between;

  .app-header-color {
    align-self: center;
    color: var(--color-primary) !important;
  }

  .app-action-buttons {
    display: flex;
    flex-direction: column;
  }

  .title-page {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
  }
  .button-back {
    width: 20px;
    font-size: 15px !important;
  }
  .icon-back-mobile {
    display: flex;
    flex-direction: row;
  }
}

.user-dropdown-actions {
  display: flex;
  align-items: center;
  margin-left: 20px;

  .user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: var(--color-primary);
    margin-right: 10px;

    p {
      color: var(--color-white);
    }
  }

  .user-name {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
      color: var(--color-gray-20);
    }
  }
}

.slideDown-enter-active {
  animation: slideDownIn 0.5s;
}

.slideDown-leave-active {
  position: absolute;
  animation: slideDownOut 0.1s;
}

@keyframes slideDownOut {
  from {
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes slideDownIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
