var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.state.isExpanded
        ? "expanded-side-menu pb-row"
        : "closed-side-menu",
    },
    [
      _c("div", { staticClass: "pb-row" }, [
        _c(
          "div",
          { staticClass: "pb-col-12" },
          [
            _c("PbButton", {
              staticClass: "toggle-side-button",
              attrs: {
                icon: _vm.state.isExpanded
                  ? "fas fa-arrow-up fa-rotate-90"
                  : "fas fa-arrow-up fa-rotate-270",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.toggleSideMenu()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.state.isExpanded
        ? _c(
            "div",
            { staticClass: "pb-row" },
            _vm._l(_vm.state.outsideWorkflowMenuData, function (menu) {
              return _c(
                "div",
                { key: menu.label, staticClass: "side-menu-icons pb-col-12" },
                [
                  menu.label !== "Hide"
                    ? _c("PbIcon", {
                        attrs: {
                          icon: _vm.validateIcon(menu.label || menu.name),
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.isWorkflowOrMenuHasChild(menu)
                              ? _vm.openMenuChildren(menu.label || menu.name)
                              : _vm.goToRoute(menu.route || menu.path)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.state.isExpanded
        ? _c(
            "div",
            { staticClass: "pb-col-12" },
            [
              _c(
                "div",
                { staticClass: "pb-row", attrs: { Style: "height: 80px" } },
                [
                  _c("div", { staticClass: "pb-col-10" }, [
                    _c("img", {
                      staticClass: "logo-image",
                      attrs: { src: _vm.adjustUrl("logo.svg"), alt: "logo" },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.state.outsideWorkflowMenuData, function (menu) {
                return _c(
                  "div",
                  { key: menu.label, staticClass: "app-action-buttons pb-row" },
                  [
                    menu.label !== "Hide"
                      ? _c("div", { staticClass: "pb-col-12" }, [
                          _c("div", { staticClass: "pb-row" }, [
                            _c(
                              "div",
                              { staticClass: "father-menu pb-col-12" },
                              [
                                _c("PbButton", {
                                  attrs: {
                                    to: menu.route,
                                    label: _vm.validateLabelSize(
                                      menu.label || menu.name
                                    ),
                                    icon: _vm.validateIcon(
                                      menu.label || menu.name
                                    ),
                                    "icon-position": "left",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.isWorkflowOrMenuHasChild(menu)
                                        ? _vm.openMenuChildren(menu.label)
                                        : _vm.goToRoute(menu.route || menu.path)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.isWorkflowOrMenuHasChild(menu)
                                  ? _c("PbIcon", {
                                      attrs: {
                                        icon: _vm.isChildrenValid(menu.label)
                                          ? "fas fa-arrow-up"
                                          : "fas fa-arrow-up fa-rotate-180",
                                        "icon-position": "right",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.openMenuChildren(
                                            menu.label
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pb-row" }, [
                            _c("div", { staticClass: "pb-col-12" }, [
                              menu.label === "Workflow" &&
                              _vm.isChildrenValid("Workflow")
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.state.menuData,
                                      function (workflowMenu) {
                                        return _c(
                                          "div",
                                          { key: workflowMenu.path },
                                          [
                                            workflowMenu.name !== "Highsales"
                                              ? _c("PbButton", {
                                                  staticClass: "children-menu",
                                                  attrs: {
                                                    to: workflowMenu.path,
                                                    disabled:
                                                      !!workflowMenu.disabled,
                                                    label: workflowMenu.name,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.goToRoute(
                                                        workflowMenu.path
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("div", [
                                    _vm.isChildrenValid(menu.label)
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            menu.menuItems,
                                            function (menuItem) {
                                              return _c("PbButton", {
                                                key: menuItem.route,
                                                staticClass: "children-menu",
                                                attrs: {
                                                  to: menuItem.route,
                                                  label: menuItem.label,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.goToRoute(
                                                      menuItem.route
                                                    )
                                                  },
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }