<template>
   <div class="app-header">
      <PbDropdown color="primary" ref="dropdown" hideCollapseIcon>
         <template #header>
            <div class="user-dropdown-actions">
               <PbIcon icon="fas fa-bars fa-lg" style="color: white" />
            </div>
         </template>

         <div class="app-action-buttons">
            <PbButton
               v-for="route in state.accessData"
               :key="route.path"
               :to="route.path"
               :disabled="!!route.disabled"
               color="white"
               buttonStyle="background"
               :label="route.name"
               @click.native="goToRoute(route.path)"
            >
            </PbButton>
         </div>
      </PbDropdown>
   </div>
</template>

<script>
import { PbDropdown, PbButton, PbIcon } from 'vue-polar-bear';
import EventBus from '../../plugins/event-bus';
import { hasAccessToPage } from '../../utils/utils';

export default {
   name: 'NavBar',

   components: {
      PbDropdown,
      PbButton,
      PbIcon,
   },

   data() {
      return {
         state: {
            isMobile: window.innerWidth <= 992,
            user: { hasTotalAccess: false },
            accessData: [],
         },
      };
   },

   mounted() {
      window.addEventListener('resize', this.getWidthSize);

      this.getAccessData();

      EventBus.$on('changeUserOptions', (hasFullAccess) => {
         this.state.user.hasTotalAccess = !!hasFullAccess;

         this.getAccessData();
      });
   },

   beforeDestroy() {
      window.removeEventListener('resize', this.getWidthSize);
   },

   methods: {
      hasAccessToPage,

      getAccessData() {
         this.state.accessData = [];

         this.$router.options.routes.forEach((route) => {
            if (route.path.includes(':id') || route.path === '/workflow')
               return false;

            route.disabled = !this.hasAccessToPage(route.path);

            this.state.accessData.push(route);
         });
      },

      goToRoute(route) {
         if (this.$router.currentRoute.path === route) return;
         this.$router.push(route);
      },

      getWidthSize(e) {
         const { target } = e;

         this.state.isMobile = target.innerWidth <= 992;
      },
   },
};
</script>

<style lang="scss" scoped>
.app-header {
   display: flex;
   justify-content: space-between;

   .app-header-color {
      color: var(--color-primary) !important;
   }

   .app-action-buttons {
      display: flex;
      flex-direction: column;
   }
}

.user-dropdown-actions {
   display: flex;
   align-items: center;
   margin-left: 20px;
}

.slideDown-enter-active {
   animation: slideDownIn 0.5s;
}

.slideDown-leave-active {
   position: absolute;
   animation: slideDownOut 0.1s;
}

@keyframes slideDownOut {
   from {
      transform: translateX(0px);
   }
   to {
      opacity: 0;
      transform: translateX(50px);
   }
}

@keyframes slideDownIn {
   from {
      opacity: 0;
      transform: translateX(50px);
   }
   to {
      opacity: 1;
      transform: translateX(0px);
   }
}
</style>
