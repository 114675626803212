<template>
  <div id="app">
    <div class="app-row-content pb-row">
      <div
        v-if="!state.isMobile"
        :class="
          state.isExpanded
            ? ' pb-col-sm-4 pb-col-md-2 pb-col-lg-3 pb-col-xl-3 pb-col-xxl-2'
            : ' pb-col-sm-3 pb-col-md-1 pb-col-lg-2 pb-col-xl-1 pb-col-xxl-1'
        "
      >
        <WorkflowSideMenu @toggle-side-menu="toggleSideMenu" />
      </div>
      <div
        :class="
          state.isExpanded
            ? 'pb-col-lg-10 pb-col-xl-9 pb-col-xxl-10'
            : 'pb-col-lg-10 pb-col-xl-10 pb-col-xxl-11'
        "
      >
        <div class="pb-row">
          <div v-if="!state.isMobile" class="app-navbar-content pb-col-12">
            <Header />
          </div>
          <div
            v-else
            class="app-navbar-content-mobile pb-col-12"
            style="display: flex; justify-content: end"
          >
            <Drawer />
          </div>
        </div>
        <div class="main pb-row app-content">
          <div class="pb-col-12">
            <PbToastNotification
              ref="toastNotification"
              :backgroundColor="state.toastNotification.color"
              :message="state.toastNotification.message"
              :duration="5000"
            />
            <div class="app-main-content">
              <div class="router-wrapper" v-if="!state.loading">
                <transition name="fade">
                  <router-view></router-view>
                </transition>
              </div>
              <div
                style="width: 100%; text-align: -webkit-center"
                v-if="state.loading"
              >
                <PbPulseLoading color="gray-80" size="80px" :borderWidth="20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-row">
      <div class="footer pb-row">
        <div class="pb-col-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { assetUrl, hasAccessToPage } from './utils/utils';
import { PbPulseLoading, PbToastNotification } from 'vue-polar-bear';
import Header from './components/Header.vue';
import EventBus from './plugins/event-bus';
import Drawer from './components/HalexComponents/MobileNavigationDrawer.vue';
import WorkflowSideMenu from './components/WorkflowSideMenu/WorkflowSideMenu.vue';

export default {
  name: 'App',

  components: {
    Header,
    PbPulseLoading,
    PbToastNotification,
    Drawer,
    WorkflowSideMenu,
  },

  data() {
    return {
      state: {
        loading: false,
        isExpanded: false,
        isMobile: window.innerWidth <= 992,
        toastNotification: {
          message: '',
          color: 'success',
        },
      },
    };
  },

  beforeMount() {
    this.$set(this.state, 'loading', true);
    const sessionId = window.localStorage.getItem('loggedUserJWT');
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    const { style } = document.documentElement;

    if (sessionId === undefined || sessionId === null)
      this.$router.push('/highsales');

    style.setProperty('--color-primary', '#123561');
    style.setProperty('--color-secondary', '#D73D70');
    style.setProperty('--color-active', '#1E618D');

    this.$store.dispatch('defineSessionId', sessionId);
    this.$store.dispatch('defineUserInfo', userInfo);
    this.$store.dispatch('reloadSalesOrders', true);
    this.$store.dispatch('defineRequestState', {});

    this.updateAxiosInstance();

    this.getApproverData();
  },

  created() {
    window.addEventListener('resize', this.verifyView);
  },
  destroyed() {
    window.removeEventListener('resize', this.verifyView);
  },

  mounted() {
    EventBus.$on('getApproverData', () => {
      this.$set(this.state, 'loading', true);
      this.getApproverData();
    });

    EventBus.$on('showMessage', (message = '', color = 'success') => {
      this.toggleToastNotification(message, color);
    });
  },

  updated() {
    this.isValidAccess();
  },

  methods: {
    verifyView(e) {
      if (e.target.innerWidth <= 992) {
        this.state.isMobile = true;
      } else {
        this.state.isMobile = false;
      }
    },

    async getApproverData() {
      const { userInfo } = this.$store.getters;

      this.getData('/approver', {
        findId: ['idUser_approver', userInfo.userId],
      })
        .then((approver) => {
          const isUserRepresentative =
            userInfo.userProfileDescription === 'Representante de Vendas';
          const isUserController =
            userInfo.userProfileDescription === 'Controladoria';

          if (!approver?.id && !isUserRepresentative && !isUserController)
            throw new Error('Aprovador não encontrado, favor tente novamente!');

          userInfo.approverId = approver?.id;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          const hasFullAccess =
            userInfo.userId === 122 ? true : !!approver?.isAdmin;

          this.$store.dispatch('defineApproverId', approver?.id);

          this.$store.dispatch('defineApproverIsActive', !!approver?.status);

          this.$store.dispatch('defineApproverHasFullAccess', hasFullAccess);

          this.$axios.defaults.headers.common['idApprover'] = approver?.id;

          EventBus.$emit('changeUserOptions', hasFullAccess);

          this.$set(this.state, 'loading', false);
        })
        .catch((error) => {
          console.error(error);
          this.toggleToastNotification(error.message);
          setTimeout(() => {
            this.$router.push('/workflow/highsales');
          }, 2000);
        })
        .finally(() => {
          if (!hasAccessToPage(this.$router.currentRoute.path)) {
            this.invalidAccess();
          }
        });
    },

    async getData(route, header) {
      return await this.$http
        .request('get', {
          path: route,
          headers: header,
          commitState: 'app',
        })
        .then((response) => {
          if (response?.listData?.hasError === true)
            throw new Error(response?.listData?.errorMessage);

          if (typeof response === 'undefined')
            throw new Error('Não foi possível obter os dados!');

          const listData = response.listData[0];

          return listData;
        })
        .catch((error) => {
          console.error(error);
          this.toggleToastNotification(error.message);
        });
    },

    adjustUrl(url) {
      return assetUrl(url);
    },

    updateAxiosInstance() {
      const { sessionId, userInfo } = this.$store.getters;

      const { userName, userId } = userInfo;

      this.$axios.defaults.headers.common[
        'authenticate'
      ] = `Bearer ${sessionId}`;
      this.$axios.defaults.headers.common['username'] = userName;
      this.$axios.defaults.headers.common['idUser'] = userId;
    },

    toggleToastNotification(message, color = 'danger') {
      this.state.toastNotification.message = message;
      this.state.toastNotification.color = color;

      if (this.$refs.toastNotification) this.$refs.toastNotification.toggle();
    },

    isValidAccess() {
      if (this.$router.currentRoute.query?.invalidAccess) {
        let message = 'Você não tem permissão para acessar a página!';

        if (this.$router.currentRoute.query?.message) {
          message = this.$router.currentRoute.query.message;
        }

        this.toggleToastNotification(message);

        this.$router.push('/workflow/sales');
      }
    },

    invalidAccess() {
      const message = 'Você não tem permissão para acessar a página!';

      this.toggleToastNotification(message);

      this.$router.push('/workflow/sales');
    },

    toggleSideMenu(isExpanded) {
      this.state.isExpanded = isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-breakpoint: 992px;

#app {
  display: flex;
  flex-direction: column;
  background-color: #f9f9fa;

  @media (max-width: $mobile-breakpoint) {
    flex-flow: column;
  }
}

.app-row-content {
  height: 100vh;
}

.app-content {
  padding: 50px;
  @media (max-width: $mobile-breakpoint) {
    padding: 20px;
  }
}

.app-content-closed {
  padding: 50px;
}

.app-navbar-content-mobile {
  background-color: #ffffff;

  @media (max-width: $mobile-breakpoint) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    width: 100%;
    height: 75px;
    background-color: var(--color-primary);
  }
}
.app-navbar-content {
  position: relative;
  padding: 50px 50px;
  padding-bottom: 10px;
  width: 100%;

  @media (max-width: $mobile-breakpoint) {
    padding-top: 35px;
  }
}

.app-main-content {
  position: relative;
  padding-bottom: 15px;
  width: 100%;
}

.header-wrapper {
  display: flex;
  justify-content: end;
  padding: 0px 30px;
  margin-bottom: 60px;

  @media (max-width: $mobile-breakpoint) {
    padding: 0px 25px;
    margin-bottom: 35px;
  }

  .router-wrapper {
    margin-right: auto;
    margin-left: auto;

    @media (max-width: $mobile-breakpoint) {
      padding: 0px 25px;
    }
  }
}

.fade-enter-active {
  animation: fadeIn 0.5s;
}

.fade-leave-active {
  position: absolute;
  width: 100%;
  animation: fadeOut 0.2s;
}

@keyframes fadeOut {
  from {
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
