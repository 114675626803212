var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-header" },
    [
      _c(
        "PbDropdown",
        {
          ref: "dropdown",
          attrs: { color: "primary", hideCollapseIcon: "" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "user-dropdown-actions" },
                    [
                      _c("PbIcon", {
                        staticStyle: { color: "white" },
                        attrs: { icon: "fas fa-bars fa-lg" },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-action-buttons" },
            _vm._l(_vm.state.accessData, function (route) {
              return _c("PbButton", {
                key: route.path,
                attrs: {
                  to: route.path,
                  disabled: !!route.disabled,
                  color: "white",
                  buttonStyle: "background",
                  label: route.name,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goToRoute(route.path)
                  },
                },
              })
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }