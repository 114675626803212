<template>
  <div
    :class="state.isExpanded ? 'expanded-side-menu pb-row' : 'closed-side-menu'"
  >
    <div class="pb-row">
      <div class="pb-col-12">
        <PbButton
          class="toggle-side-button"
          @click.native="toggleSideMenu()"
          :icon="
            state.isExpanded
              ? 'fas fa-arrow-up fa-rotate-90'
              : 'fas fa-arrow-up fa-rotate-270'
          "
        />
      </div>
    </div>
    <div v-if="!state.isExpanded" class="pb-row">
      <div
        v-for="menu of state.outsideWorkflowMenuData"
        :key="menu.label"
        class="side-menu-icons pb-col-12"
      >
        <PbIcon
          v-if="menu.label !== 'Hide'"
          :icon="validateIcon(menu.label || menu.name)"
          @click.native="
            isWorkflowOrMenuHasChild(menu)
              ? openMenuChildren(menu.label || menu.name)
              : goToRoute(menu.route || menu.path)
          "
        />
      </div>
    </div>
    <div class="pb-col-12" v-if="state.isExpanded">
      <div class="pb-row" Style="height: 80px">
        <div class="pb-col-10">
          <img class="logo-image" :src="adjustUrl('logo.svg')" alt="logo" />
        </div>
      </div>
      <div
        v-for="menu of state.outsideWorkflowMenuData"
        :key="menu.label"
        class="app-action-buttons pb-row"
      >
        <div class="pb-col-12" v-if="menu.label !== 'Hide'">
          <div class="pb-row">
            <div class="father-menu pb-col-12">
              <PbButton
                :to="menu.route"
                :label="validateLabelSize(menu.label || menu.name)"
                :icon="validateIcon(menu.label || menu.name)"
                icon-position="left"
                @click.native="
                  isWorkflowOrMenuHasChild(menu)
                    ? openMenuChildren(menu.label)
                    : goToRoute(menu.route || menu.path)
                "
              />
              <PbIcon
                v-if="isWorkflowOrMenuHasChild(menu)"
                :icon="
                  isChildrenValid(menu.label)
                    ? 'fas fa-arrow-up'
                    : 'fas fa-arrow-up fa-rotate-180'
                "
                icon-position="right"
                @click.native="openMenuChildren(menu.label)"
              />
            </div>
          </div>
          <div class="pb-row">
            <div class="pb-col-12">
              <div
                v-if="menu.label === 'Workflow' && isChildrenValid('Workflow')"
              >
                <div
                  v-for="workflowMenu of state.menuData"
                  :key="workflowMenu.path"
                >
                  <PbButton
                    v-if="workflowMenu.name !== 'Highsales'"
                    class="children-menu"
                    :to="workflowMenu.path"
                    :disabled="!!workflowMenu.disabled"
                    :label="workflowMenu.name"
                    @click.native="goToRoute(workflowMenu.path)"
                  />
                </div>
              </div>
              <div v-else>
                <div v-if="isChildrenValid(menu.label)">
                  <PbButton
                    v-for="menuItem of menu.menuItems"
                    class="children-menu"
                    :key="menuItem.route"
                    :to="menuItem.route"
                    :label="menuItem.label"
                    @click.native="goToRoute(menuItem.route)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PbButton, PbIcon } from 'vue-polar-bear';
import { assetUrl, hasAccessToPage } from '../../utils/utils';
import * as singleSpa from 'single-spa';

export default {
  name: 'WorkflowSideMenu',

  components: {
    PbButton,
    PbIcon,
  },

  data() {
    return {
      state: {
        menuData: [],
        outsideWorkflowMenuData: [],
        userInfo: {},
        isExpanded: false,
        showChildren: false,
        children: '',
      },
    };
  },

  mounted() {
    this.getOutsideWorkflowMenuData();
    this.getWorkflowMenuData();
    this.validateHighSalesButton();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 992;
    },

    hideMenuText() {
      return window.innerWidth <= 1180;
    },
  },

  methods: {
    validateLabelSize(label) {
      if (label.length > 15 && this.isMobile) {
        return label.substring(0, 15) + '...';
      } else if (label.length > 20 && this.hideMenuText) {
        return label.substring(0, 20) + '...';
      }
      return label;
    },

    toggleSideMenu() {
      this.state.isExpanded = !this.state.isExpanded;
      this.$emit('toggle-side-menu', this.state.isExpanded);
    },

    openMenuChildren(menuLabel) {
      if (this.state.isExpanded === false) this.toggleSideMenu();

      this.state.showChildren = !this.state.showChildren;
      this.state.children === ''
        ? (this.state.children = menuLabel)
        : (this.state.children = '');
    },

    getOutsideWorkflowMenuData() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));

      /*
        Assim que a nova tela de Dashboard estiver pronta e funcionando corretamente,
        deve-se remover a referência à antiga tela de Dashboard, deletando a linha
        "Dashboard" na tabela "tbMenu" do banco de dados, e apagando o método
        "removeLegacyDashboardRouteFromUserInfoMenu()" abaixo.
      */
      userInfo.menu = this.removeLegacyDashboardRouteFromUserInfoMenu(
        userInfo.menu,
      );

      userInfo.menu = this.includesVueRoutesToUserInfoMenu(userInfo.menu);

      this.state.outsideWorkflowMenuData = [
        ...userInfo.menu.sort(function (a, b) {
          return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
        }),
      ];
    },

    getWorkflowMenuData() {
      this.$router.options.routes.forEach((route) => {
        if (
          route.parentMenu !== 'Workflow' ||
          route.path.includes(':id') ||
          route.path === '/workflow'
        )
          return false;

        route.disabled = !hasAccessToPage(route.path);
        this.state.menuData.push(route);
      });
    },

    validateHighSalesButton() {
      for (const menu of this.state.menuData) {
        if (menu.name === 'Highsales')
          this.state.outsideWorkflowMenuData.push(menu);
      }
    },

    goToRoute(route) {
      if (!route || route === '') return;
      if (this.$router.currentRoute.path === route) return;

      route.includes('/workflow')
        ? this.$router.push(route)
        : singleSpa.navigateToUrl('/' + route);

      if (this.state.isExpanded) this.toggleSideMenu();
    },

    isWorkflowOrMenuHasChild(menu) {
      if (menu?.label === 'Workflow' || menu?.menuItems) return true;
      return false;
    },

    isChildrenValid(menuLabel) {
      if (this.state.showChildren && this.state.children === menuLabel)
        return true;
      return false;
    },

    validateIcon(buttonName) {
      const icons = {
        'Dashboard de Representantes': 'fas fa-home',
        Controladoria: 'fas fa-users',
        'Administração de Vendas': 'fas fa-chart-line',
        'Controle de Acesso': 'fas fa-lock',
        Vendas: 'fas fa-shopping-cart',
        'Logística': 'fas fa-truck',
        'Licitações': 'fas fa-file-alt',
        Highsales: 'fas fa-chart-line',
        Workflow: 'fas fa-bezier-curve',
        'Opções de Usuário': 'fas fa-cog',
        'Análise de Margem': 'fas fa-calculator',
        'Condições de Pagamento': 'fas fa-credit-card'
      };

      return icons[buttonName];
    },

    validateMenuOrder(menuName) {
      const menuOrder = {
        'Dashboard de Representantes': 1,
        Controladoria: 2,
        'Administração de Vendas': 3,
        'Controle de Acesso': 4,
        Vendas: 5,
        Logística: 6,
        Licitações: 7,
        Workflow: 8,
        'Opções de Usuário': 9,
        Highsales: 10,
      };
      return menuOrder[menuName];
    },

    adjustUrl(url) {
      return assetUrl(url);
    },

    includesVueRoutesToUserInfoMenu(userInfoMenu) {
      this.$router.options.routes.forEach((route) => {
        /*
        Solução temporária para substituir um menu do legado
        por um do workflow. Deve ser melhor implementado
       */
        if (route.parentMenu === 'Administração de Vendas') {
          userInfoMenu.forEach((menu) => {
            if (menu.label === route.parentMenu) {
              menu.menuItems[0].route = route.path;
            }
          });
        }

        if (!route?.parentMenu && route.path !== '/workflow') {
          userInfoMenu.push({
            label: route.name,
            route: route.path,
            order: this.validateMenuOrder(route.name),
            icon: this.validateIcon(route.name),
          });
        }
      });
      return userInfoMenu;
    },

    removeLegacyDashboardRouteFromUserInfoMenu(userInfoMenu) {
      const oldDashboardIndex = userInfoMenu.findIndex(
        (menu) => menu.label === 'Dashboard',
      );
      userInfoMenu.splice(oldDashboardIndex, 1);
      return userInfoMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.expanded-side-menu {
  background-color: #ffffff;
  max-width: 361px;
  height: 100%;
  border-right: 1px solid #e5e5e5;
  font-size: 0.93rem;
  display: flex;
  align-content: flex-start;

  .toggle-side-button {
    margin-left: 30px;
  }

  .logo-image {
    position: absolute;
    margin-left: 30px;
  }

  .app-action-buttons {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    margin-left: 30px;

    .father-menu {
      display: flex;
      justify-content: space-between;
      padding-right: 25px;
      align-items: center;
    }

    .children-menu {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-left: 30px;
    }
  }

  @media (min-width: 992px) {
    .father-menu p {
      .pb-strong {
        font-size: 0.8rem;
      }
    }
  }
}

.closed-side-menu {
  background-color: #ffffff;
  max-width: 60px;
  height: 100%;

  .side-menu-icons {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: 30px;
  }
}
</style>
