import axios from 'axios';

const user = JSON.parse(localStorage.getItem('userInfo'));
const authenticate = 'Bearer ' + localStorage.getItem('loggedUserJWT');

const instance = async () => {
  return axios.create({
    baseURL: `${process.env.SERVICE_NT_HOST}:${process.env.SERVICE_NT_PORT}/${process.env.SERVICE_NT_URL}`,
    headers: {
      authenticate,
      username: user.userName,
      idUser: user.userId,
      pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store',
    },
    timeout: 30000,
  });
};
export default instance;
