import Vue from 'vue';
import store from '../store';

export const removePriceCurrencyFromString = (value) => {
  let price = value?.toString();

  if (
    price?.includes('R$') ||
    price?.includes('.') ||
    price?.includes(',')
  ) {
    price = price?.replace('R$', '');
    price = price?.replace('.', '');
    price = price?.replace(',', '.');
  }

  return price;
}

export const dateFormatting = (date, separator = '/') => {
  return `${formattedDateNumber(
    date.getDate(),
  )}${separator}${formattedDateNumber(
    date.getMonth() + 1,
  )}${separator}${date.getFullYear()}`;
};

export const formattedDateNumber = (number) => {
  let newNumber = parseInt(number);

  if (newNumber > 0 && newNumber < 10) {
    newNumber = `0${newNumber}`;
  }

  return newNumber;
};

export const formatData = (type, dataToFormat, limit = 99) => {
  let formattedData = dataToFormat;
  let typeValidate = String(type).toUpperCase();
  let objectFormat = '';

  if (typeValidate.includes('_')) {
    objectFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: typeValidate.split('_')[1],
    });
    typeValidate = typeValidate.split('_')[0];

    if (typeof formattedData === 'string') {
      typeValidate = 'string';
    }
  }

  if (
    formattedData === undefined ||
    formattedData === null ||
    formattedData === ''
  ) {
    return formattedData;
  }

  switch (typeValidate) {
    case 'NUMBER':
      formattedData = parseFloat(dataToFormat);
      break;

    case 'DATE':
      formattedData = formattedData.includes('Z')
        ? dateFormatting(new Date(dataToFormat))
        : formattedData;
      break;

    case 'PERCENT':
      formattedData = `${parseFloat(dataToFormat)} %`;
      break;

    case 'PRICE':
      formattedData = objectFormat.format(dataToFormat);
      break;

    case 'CHECK-LIST':
      formattedData = dataToFormat.map(values => parseInt(values));
      break;

    default:
      formattedData = String(dataToFormat);
      formattedData =
        formattedData.split('').length > limit
          ? `${formattedData.slice(0, limit)}...`
          : formattedData;
      break;
  }

  return formattedData;
};

export const assetUrl = (url) => {
  // eslint-disable-next-line no-undef
  const publicPath = __webpack_public_path__;
  const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
  const urlPrefix = url.startsWith('/') ? '' : '/';

  return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
};

export const getStepsData = (values, steps) => {
  let allData = {};

  values.forEach((step, index) => {
    const hasCrud = steps[index] && steps[index].crud !== undefined;
    const crudName = hasCrud ? steps[index].crud.value : '';
    const newCrudName = hasCrud ? steps[index].crud.crudValue : '';
    let stepData = { ...step };

    if (hasCrud && stepData[crudName].length >= 1) {
      let stepItem = stepData[crudName];

      stepItem.forEach((stepFields, index) => {
        const fieldsReturn = getFieldValues(stepFields);
        stepItem[index] = fieldsReturn;
      });

      stepData[newCrudName ? newCrudName : crudName] = stepItem;

      if (newCrudName) {
        delete stepData[crudName];
      }
    } else {
      const fields = getFieldValues(stepData);

      stepData = fields;
    }

    allData = { ...allData, ...stepData };
  });

  return allData;
};

export const getFieldValues = (fields) => {
  let returnFields = {};

  for (let itemName in fields) {
    let item = fields[itemName];

    if (typeof item === 'object') {
      let newItem = Object.assign({}, item);

      newItem = newItem.value;

      itemName === 'undefined' || itemName === undefined
        ? (returnFields = newItem)
        : (returnFields[itemName] = newItem);
    } else {
      returnFields[itemName] = item;
    }
  }

  return returnFields;
};

export const defineRequestParameters = (requestParameter, page = 1) => {
  const { sessionId } = store.getters;
  const headers = getRequestHeader(page);
  const request = {};

  request.path = `/${requestParameter}`;
  request.commitState = requestParameter;
  request.headers = headers;
  request.page = 1;
  request.sessionId = sessionId;

  return request;
};

export const getRequestHeader = (page, limitHeader) => {
  const offset = page > 1 ? page - 1 : 0;
  const limit = limitHeader ? limitHeader : 10;
  const headers = { offset, limit };

  return headers;
};

export const genericDataSender = (
  type,
  data,
  requestName,
  sendAsArray = false,
) => {
  const requestParameter = defineRequestParameters(requestName);

  return new Promise((resolve, reject) => {
    const adjustedData =
      typeof data === 'object' ? genericDataAdjust(data) : data;
    let requestData = sendAsArray ? [adjustedData] : adjustedData;
    let requestType = 'post';

    if (adjustedData.id) {
      requestParameter.path += `/${adjustedData.id}`;
    }

    switch (type.toUpperCase()) {
      case 'SEND':
        requestType = adjustedData.id ? 'put' : 'post';
        break;

      case 'DELETE':
        requestType = 'delete';
        break;

      default:
        reject('Ocorreu um erro!');
        break;
    }

    Vue.prototype.$http
      .request(requestType, requestParameter, requestData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const genericDataAdjust = (data) => {
  let newData = { ...data };

  delete newData['createdAt'];
  delete newData['updatedAt'];

  for (const itemData in newData) {
    let item = newData[itemData];

    if (typeof item === 'object') {
      item = item?.value !== undefined ? item.value : item;

      newData[itemData] = item;
    }
  }

  return newData;
};

export const hasAccessToPage = (path) => {
  const { approverHasFullAccess } = store.getters;
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  const isUserRepresentative = userInfo?.userProfileDescription === 'Representante de Vendas';
  const isUserController = userInfo?.userProfileDescription === 'Controladoria';
  const allowedPaths = [
    '/',
    '/workflow',
    '/workflow/highsales'
  ]

  if (isUserController || (isUserRepresentative && (allowedPaths.includes(path) || path === '/workflow/dashboard'))) {
    return true;
  }

  if (!isUserRepresentative && (approverHasFullAccess || allowedPaths.includes(path)
    || path === '/workflow/approveLogs' || path.includes('/sales')))
    return true;

  return false;
};
