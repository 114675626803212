import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/workflow',
    name: 'Dashboard',
    component: Home,
  },
  {
    path: '/workflow/sales',
    name: 'Pedidos',
    parentMenu: 'Workflow', // Valida se a rota deve ser inclusa em algum submenu.
    component: () => import('../views/SalesOrders/OrderList.vue'),
    beforeEnter: (to, from, next) => {
      if (isUserApprover()) {
        if (from.path.includes('//')) {
          store.dispatch('reloadSalesOrders', true);
        }
        next();
      }
      else {
        next('/workflow/dashboard');
      }
    },
  },
  {
    path: '/workflow/sales/:id',
    name: 'Pedido',
    parentMenu: 'Workflow',
    component: () => import('../views/SalesOrders/OrderDetail.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/flow',
    name: 'Flows',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/Flow/Flow.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/flow/:id',
    name: 'Configuração do Flow',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/Flow/FlowConfiguration.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },

  {
    path: '/workflow/approvers',
    name: 'Aprovadores',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/DynamicCruds/Approvers.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/discounts',
    name: 'Descontos',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/DynamicCruds/Discounts.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/departments',
    name: 'Departamentos',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/DynamicCruds/Departments.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/levels',
    name: 'Níveis',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/DynamicCruds/Levels.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/contracts',
    name: 'Contratos',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/DynamicCruds/Contracts.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },

  {
    path: '/workflow/approveLogs',
    name: 'Histórico de aprovações',
    parentMenu: 'Workflow',
    component: () => import('../views/Logs/ApproveLogs.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },

  {
    path: '/workflow/specialClient',
    name: 'Clientes Especiais',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/SpecialClients/SpecialClients.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/specialClient/:id',
    name: 'Cadastro de Clientes Especiais',
    parentMenu: 'Workflow',
    component: () => import('../views/Cruds/SpecialClients/SpecialClientsConfiguration.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/highsales',
    name: 'Highsales',
    parentMenu: 'Workflow',
  },
  {
    path: '/workflow/campaigns',
    name: 'Cadastro de Promoções',
    parentMenu: 'Workflow',
    component: () => import('../views/Campaigns/Campaigns.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/regionalManagers',
    name: 'GR x Representantes',
    parentMenu: 'Workflow',
    component: () => import('../views/SalesAdministration/RegionalManagers.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next);
    }
  },
  {
    path: '/workflow/dashboard',
    name: 'Dashboard de Representantes',
    component: () => import('../views/RepresentativesDashboard.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next, to);
    }
  },
  {
    path: '/workflow/goalsManagement',
    name: 'Gestão de Metas',
    parentMenu: 'Administração de Vendas',
    component: () => import('../views/SalesAdministration/GoalsManagement.vue'),
    beforeEnter: (to, from, next) => {
      validateRoutePermission(next, to);
    }
  },
  {
    path: '/workflow/marginAnalysis',
    name: 'Análise de Margem',
    // parentMenu: 'Controladoria',
    component: () => import('../views/Controllership/MarginAnalysis.vue'),
    beforeEnter: (to, from, next) => {
      validateMarginAnalysisPermission(next);
    },
  },
  {
    path: '/workflow/productGroupEdit',
    name: 'Edição de Grupo de Produtos',
    parentMenu: 'Workflow',
    component: () => import('../views/ProductGroupEdit.vue'),
    beforeEnter: (to, from, next) => {
      validateSalesAdmPermission(next);
    }
  },
  {
    path: '/workflow/payConditionManagement',
    name: 'Condições de Pagamento',
    component: () => import('../views/SalesAdministration/PayConditionManagement.vue'),
    beforeEnter: (to, from, next) => {
      validateSalesAdmPermission(next);
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

const validateRoutePermission = (next, to) => {
  const DASHBOARD_PATH = '/workflow/dashboard'
  const HIGHSALES_PATH = '/workflow/highsales'
  const GOALSMANAGEMENT_PATH = '/workflow/goalsManagement'
  const MARGINANALYSIS_PATH = '/workflow/marginAnalysis'

  const redirectTo = to?.path === DASHBOARD_PATH ? HIGHSALES_PATH : DASHBOARD_PATH;
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  if (userInfo.userProfileDescription === 'Controladoria') {
    next(MARGINANALYSIS_PATH);
    return;
  }

  const shouldAllowAccess = verifyUserInfo(to?.path, DASHBOARD_PATH, GOALSMANAGEMENT_PATH, userInfo)

  shouldAllowAccess ? next() : next(redirectTo);
}

const verifyUserInfo = (currentPath, dashboardPath, goalsManagementPath, userInfo) => {
  const isUserAdmin = userInfo?.userProfileId === 4

  if (currentPath === goalsManagementPath) {
    const userIsSalesAdm = userInfo?.userProfileId === 2

    return userIsSalesAdm || isUserAdmin
  } else if (currentPath === dashboardPath) {
    const userIsRepresentative = userInfo?.userProfileDescription === 'Representante de Vendas'

    return userIsRepresentative || isUserApprover()
  } else {
    return isUserApprover();
  }
}

const isUserApprover = () => {
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  return !!userInfo.approverId
}

const validateMarginAnalysisPermission = (next) => {
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  const isUserController = userInfo.userId === 151
  const isUserAdmin = userInfo?.userProfileId === 4

  if (isUserController || isUserAdmin) {
    next();
    return;
  }

  next('/workflow/highsales')
  return;
}

const validateSalesAdmPermission = (next) => {
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  const isSalesAdm = userInfo?.userProfileId === 2;
  const isUserAdmin = userInfo?.userProfileId === 4

  if (isSalesAdm || isUserAdmin) {
    next();
    return;
  }

  next('/workflow/highsales');
  return;
}

export default router;
