var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-header" }, [
    _c(
      "div",
      { staticClass: "title-page" },
      [
        _vm.isReturnable
          ? _c("PbButton", {
              staticClass: "button-back",
              attrs: {
                color: "primary",
                buttonStyle: "regular",
                icon: "fas fa-arrow-up fa-rotate-270",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.goBack.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("h2", { staticClass: "pb app-header-color" }, [
          _vm._v(_vm._s(_vm.pageName) + _vm._s(_vm.getLastSubPageId)),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.state.isMobile,
            expression: "!state.isMobile",
          },
        ],
        staticClass: "icon-back-mobile",
      },
      [
        _c("Notification", {
          on: { openNotification: _vm.state.notification.route },
        }),
        _vm._v(" "),
        _c(
          "PbDropdown",
          {
            ref: "dropdown",
            attrs: { color: "primary" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "user-dropdown-actions" }, [
                      _c("div", { staticClass: "user-avatar" }, [
                        _c("p", { staticClass: "pb" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getInitialsName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      !_vm.state.isMobile
                        ? _c(
                            "div",
                            {
                              staticClass: "user-name",
                              attrs: { title: _vm.state.user.userName },
                            },
                            [
                              _c("p", { staticClass: "pb disable-select" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.state.user.userName) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _vm._l(_vm.state.accessData, function (route) {
              return _c(
                "div",
                { key: route.path, staticClass: "app-action-buttons" },
                [
                  route.name !== "Cadastro de Promoções"
                    ? _c("PbButton", {
                        attrs: {
                          to: route.path,
                          disabled: !!route.disabled,
                          color: "white",
                          buttonStyle: "background",
                          label: route.name,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToRoute(route.path)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }