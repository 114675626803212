import axios from 'axios';

const instance = axios.create({
   baseURL: `${process.env.HOST_PROTOCOL}://${process.env.HOST_CONNECT}:${process.env.HOST_PORT}/api`,
});

instance.defaults.headers.common['pragma'] = 'no-cache';
instance.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';

export default instance;
