<template>
   <div>
   </div>
</template>

<script>

export default {
   name: 'Home',

   data() {
      return {};
   },

   beforeCreate() {
     this.$store.dispatch('reloadSalesOrders', true);
     this.$router.push('/workflow/sales');
   },

   methods: {},
};
</script>

<style lang="scss" scoped>
</style>
