import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import router from './router';
import store from './store/index';

import App from './App.vue';

import axios from './utils/axios.js';
import * as integration from './utils/integration.js';

Vue.config.productionTip = true;
Vue.prototype.$axios = axios;
Vue.prototype.$http = integration;

const vueLifecycles = singleSpaVue({
   Vue,
   appOptions: {
      render(h) {
         return h(App, {
            props: {},
         });
      },
      router,
      store,
   },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
