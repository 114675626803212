<template>
  <div>
    <PbButton
      color="primary"
      buttonStyle="regular"
      style="
        flex-shrink: 0;
        margin: 0;
        margin-left: 12px;
        font-size: 20px !important;
      "
      icon="far fa-bell"
      notificationBadgePosition="top-right"
      :notification-badge-value="unreadItemsLength"
      notificationBadgeColor="#FF7614"
      @click.native="() => (isNotificationModalOpen = !isNotificationModalOpen)"
    />

    <div
      v-click-outside="closeNotificationModal"
      v-if="isNotificationModalOpen"
      class="container-notification"
    >
      <div class="pb-row container-items-notification">
        <div class="pb-col-12 header-notification">
          <div class="pb-row" style="align-items: center">
            <div class="pb-col-4 title-header">Notificações</div>
            <div class="pb-col-8">
              <div
                class="pb-row"
                style="justify-content: end; margin-right: 16px"
              >
                <div class="pb-col-5">
                  <PbButton
                    class="button-formatted"
                    style="width: 80px"
                    color="gray"
                    buttonStyle="background"
                    @click.native="showReadOrUnreadButton()"
                  >
                    {{ showUnreadNotifications ? 'Todos' : 'Não lidos' }}
                  </PbButton>
                </div>
                <div class="pb-col-5">
                  <PbButton
                    class="button-formatted"
                    color="gray"
                    buttonStyle="background"
                    @click.native="clearAll()"
                  >
                    Limpar tudo
                  </PbButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pb-col-12 content-notification pb-scroll-primary"
          style="height: 300px; overflow: auto"
        >
          <div
            v-for="(item, index) in notification"
            class="pb-row content-items-notification"
            :style="`
          ${'background-color: #F5F6F7;'}
          ${
            item.active
              ? 'font-weight: bolder; color: #292D32;'
              : 'color: var(--color-gray-40);'
          }`"
            :key="index"
          >
            <div
              class="pb-col-2 clickOnNotification"
              v-on:click="openNotification(item.link)"
            >
              <div class="pb-row">
                <div class="pb-col-12 icon-item-notification">
                  <PbIcon icon="far fa-envelope" />
                </div>
              </div>
            </div>
            <div
              class="pb-col-8 clickOnNotification"
              v-on:click="openNotification(item.link)"
            >
              <div class="pb-row content-text">
                <div class="pb-col-12 limit-characters title-notification">
                  {{ item.title }}
                </div>
                <div class="pb-col-12 limit-characters subtitle-notification">
                  {{ item.message }}
                </div>
              </div>
            </div>
            <div
              v-if="!item.read"
              class="pb-col-1 clickOnNotification"
              v-on:click="markNotificationAsRead(item.id)"
            >
              <div class="notification-button">
                <PbIcon icon="fas fa-eye-slash" />
              </div>
            </div>
            <div
              class="pb-col-1 clickOnNotification"
              v-on:click="deleteSingleNotification(item.id)"
            >
              <div class="notification-button">
                <PbIcon icon="fas fa-trash" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PbButton, PbIcon } from 'vue-polar-bear';
import instance from '../../utils/axiosNotification';
export default {
  name: 'Notification',

  components: {
    PbButton,
    PbIcon,
  },

  props: {
    col: { type: String },
  },

  data() {
    return {
      isNotificationModalOpen: false,
      showUnreadNotifications: true,
      notification: [],
      unreadItemsLength: 0,
      mouseHover: {},
    };
  },

  mounted() {
    this.getUnreadNotificationsById();
  },

  methods: {
    closeNotificationModal() {
      this.isNotificationModalOpen = false;
    },

    showReadOrUnreadButton() {
      this.showUnreadNotifications = !this.showUnreadNotifications;

      if (this.showUnreadNotifications) {
        this.getUnreadNotificationsById();
      } else {
        this.getAllNotificationsByUserId();
      }
    },

    async getAllNotificationsByUserId() {
      const { userId } = this.$store.getters.userInfo;

      const connect = await instance();
      const notifications = await connect['get'](
        `notification/${userId}`,
      ).catch((error) => {
        console.error(error);
      });

      this.notification = notifications?.data;
    },

    async getUnreadNotificationsById() {
      const { userId } = this.$store.getters.userInfo;

      const connect = await instance();
      const notifications = await connect['get'](
        `notification/unread/${userId}`,
      ).catch((error) => {
        console.error(error);
      });

      this.notification = notifications?.data;
      this.unreadItemsLength = this.notification?.length;
    },

    async clearAll() {
      const { userId } = this.$store.getters.userInfo;
      const connect = await instance();

      await connect['delete'](`notification/clearAll/${userId}`).catch(
        (error) => {
          console.error(error);
        },
      );

      this.notification = [];
      this.unreadItemsLength = 0;
    },

    async deleteSingleNotification(id) {
      const connect = await instance();

      await connect['delete'](`notification/${id}`).catch((error) => {
        console.error(error);
      });

      this.notification = this.notification.filter((item) => item?.id !== id);
    },

    async markNotificationAsRead(id) {
      const connect = await instance();
      const body = {
        read: true,
      };

      await connect['put'](`notification/${id}`, body).catch((error) => {
        console.error(error);
      });

      if (this.showUnreadNotifications) {
        this.notification = this.notification.filter((item) => item?.id !== id);
        this.unreadItemsLength = this.notification?.length;
      } else {
        this.notification = this.notification.map((item) => {
          if (item?.id === id) {
            item.read = true;
          }
          return item;
        });

        this.unreadItemsLength > 0
          ? (this.unreadItemsLength -= 1)
          : (this.unreadItemsLength = 0);
      }
    },

    openNotification(value) {
      if (!value) return;

      this.$emit('openNotification', value);
      this.isNotificationModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary-halex: #123561;

.container-notification {
  .container-items-notification {
    border-width: thin;
    border-style: solid;
    border-color: var(--color-gray-10);
    display: block;
    position: absolute;
    width: 375px;
    border-radius: 5px 0 5px 5px;
    top: 60px;
    right: 320px;
    padding: 4px;
    background-color: #ffffff;
    z-index: 3;
    box-shadow: 0px 4px 7px -1px rgba(82, 89, 91, 0.14);
    border-radius: 8px 0px 8px 8px;

    .header-notification {
      text-align: left;
      padding: 12px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 700;

      .title-header {
        font-size: 20px;
      }

      .button-formatted {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-size: 12px !important;
        color: var(--color-gray-50) !important;
        padding: 15px !important;
        height: 35px !important;
      }

      .button-formatted:hover {
        color: #123561 !important;
      }
    }

    .content-notification {
      .content-items-notification {
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        padding: 12px;
        margin-bottom: 6px;
        margin-right: 8px;
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;

        .clickOnNotification {
          cursor: pointer;
        }

        .icon-item-notification {
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: $color-primary-halex;
          color: #ffffff;
        }

        .content-text {
          font-family: 'Nunito', sans-serif;
          margin-left: 10px;

          .limit-characters {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .title-notification {
            font-size: 15px;
            padding-bottom: 6px;
          }

          .subtitle-notification {
            font-size: 13px;
          }
        }

        .notification-button {
          font-weight: bold;
          width: 15px;
        }
      }

      .content-items-notification:hover {
        background-color: var(--color-gray-10) !important;
        color: var(--color-gray-50) !important;
      }
    }
  }
}
</style>
