import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
   sessionId: '',
   reloadSalesOrders: true,
   userInfo: {},
   requests: {},
   saleOrder: {},
   approverId: '',
   flowConfiguration: {},
   approverIsActive: false,
   approverHasFullAccess: '',
   specialClientConfiguration: {},
};

const mutations = {
   DEFINE_SESSION_ID_STATE(state, sessionId) {
      state.sessionId = sessionId;
   },

   DEFINE_USER_INFO_STATE(state, userInfo) {
      state.userInfo = userInfo;
   },

   DEFINE_REQUEST_STATE(state, requestState) {
      state.requests = requestState;
   },

   DEFINE_USER_STATE(state, user) {
      state.user = user;
   },

   DEFINE_SALES_ORDERS_RELOAD_STATE(state, reloadSalesOrders) {
      state.reloadSalesOrders = reloadSalesOrders;
   },

   DEFINE_SALE_ORDER_STATE(state, saleOrder) {
      state.saleOrder = saleOrder;
   },

   DEFINE_APPROVER_ID_STATE(state, approverId) {
      state.approverId = approverId;
   },

   DEFINE_FLOW_CONFIGURATION_STATE(state, flow) {
      state.flowConfiguration = flow;
   },

   DEFINE_APPROVER_IS_ACTIVE_STATE(state, isActive) {
      state.approverIsActive = isActive;
   },

   DEFINE_APPROVER_HAS_FULL_ACCESS_STATE(state, hasFullAccess) {
      state.approverHasFullAccess = hasFullAccess;
   },

   DEFINE_SPECIAL_CLIENT_CONFIGURATION_STATE(state, specialClient) {
    state.specialClientConfiguration = specialClient;
 },
};

const actions = {
   defineSessionId({ commit }, sessionId) {
      commit('DEFINE_SESSION_ID_STATE', sessionId);
   },

   defineUserInfo({ commit }, userInfo) {
      commit('DEFINE_USER_INFO_STATE', userInfo);
   },

   defineRequestState({ commit }, requestState) {
      commit('DEFINE_REQUEST_STATE', requestState);
   },

   defineUser({ commit }, user) {
      commit('DEFINE_USER_STATE', user);
   },

   flowConfiguration({ commit }, flow) {
      commit('DEFINE_FLOW_CONFIGURATION_STATE', flow);
   },

   reloadSalesOrders({ commit }, reloadSalesOrders) {
      commit('DEFINE_SALES_ORDERS_RELOAD_STATE', reloadSalesOrders);
   },

   defineSaleOrder({ commit }, saleOrder) {
      commit('DEFINE_SALE_ORDER_STATE', saleOrder);
   },

   defineApproverId({ commit }, approverId) {
      commit('DEFINE_APPROVER_ID_STATE', approverId);
   },

   defineApproverIsActive({ commit }, isActive) {
      commit('DEFINE_APPROVER_IS_ACTIVE_STATE', isActive);
   },

   defineApproverHasFullAccess({ commit }, hasFullAccess) {
      commit('DEFINE_APPROVER_HAS_FULL_ACCESS_STATE', hasFullAccess);
   },

   specialClientConfiguration({ commit }, specialClient) {
    commit('DEFINE_SPECIAL_CLIENT_CONFIGURATION_STATE', specialClient);
 },
};

const getters = {
   reloadSalesOrders(state) {
      return state.reloadSalesOrders;
   },
   sessionId(state) {
      return state.sessionId;
   },
   userInfo(state) {
      return state.userInfo;
   },
   approverId(state) {
      return state.approverId;
   },
   requests(state) {
      return state.requests;
   },
   saleOrder(state) {
      return state.saleOrder;
   },
   flowConfiguration(state) {
      return state.flowConfiguration;
   },
   approverHasFullAccess(state) {
      return state.approverHasFullAccess;
   },
   approverIsActive(state) {
      return state.approverIsActive;
   },
   specialClientConfiguration(state) {
      return state.specialClientConfiguration;
   },
};

const store = new Vuex.Store({
   devtools: true,
   mutations,
   state,
   actions,
   getters,
   plugins: [],
});
export default store;
