import Vue from 'vue';
import store from '../store';

export async function request(type = 'get', requestData, body) {
   const { path, headers, commitState } = requestData;
   const requestState = getRequestState(commitState);
   let responseData = {};

   const request = Vue.prototype.$axios[type];

   return await request(
      path,
      type === 'get' ? { headers } : body,
      type === 'put ' || type === 'post' ? { headers } : '',
   )
      .then((response) => {
         const newResponseData = defineResponseData(response, requestData);

         if (typeof newResponseData.listData !== 'object') {
            newResponseData.listData = [];
         }

         if (
            responseData.listData?.Error !== undefined ||
            responseData.listData?.err !== undefined
         )
            throw new Error(
               `Ocorreu um problema ao obter os dados: ${
                  responseData.listData?.Error
                     ? responseData.listData?.Error.message
                     : responseData.listData?.err
               } !`,
            );

         responseData = { ...responseData, ...newResponseData };

         return responseData;
      })
      .catch((error) => {
         console.error(error);

         const newResponseData = defineResponseDataError(error);

         responseData = { ...responseData, ...newResponseData };
      })
      .finally(() => {
         const lastRequestData =
            requestState[commitState][
               type === 'put' || type === 'post' ? 'send' : type
            ];

         requestState[commitState][
            type === 'put' || type === 'post' ? 'send' : type
         ] = {
            ...lastRequestData,
            ...responseData,
         };

         store.dispatch('defineRequestState', requestState);

         return responseData;
      });
}

export const getFunctionParameters = (param) => {
   return (
      param
         .toString()
         .replace(/((\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s))/gm, '')
         // eslint-disable-next-line no-useless-escape
         .match(/^function\s*[^\(]*\(\s*([^\)]*)\)/m)[1]
         .split(/,/)
   );
};

export const getStoreActions = (mutations) => {
   const actions = {};

   for (let mutation in mutations) {
      let parameters = getFunctionParameters(mutations[mutation])[1];
      actions[parameters] = mutation;
   }

   return actions;
};

function defineResponseData(response, requestData) {
   const { page, sessionId } = requestData;
   const { data } = response;
   const result = data.result !== undefined ? data.result : data;
   const responseData = {};

   if (typeof result === 'undefined')
      throw new Error('Não foi possível obter os dados!');

   responseData.listData = result.rows === undefined ? result : result.rows;
   if (result.totalOfRows) {
      responseData.totalRegisters = result.totalOfRows;
   }
   responseData.page = page;
   responseData.sessionId = sessionId;

   if (responseData.listData?.Error) {
      responseData.listData.hasError = true;
      responseData.listData.errorMessage = translateErrorMessage(
         responseData.listData?.Error,
      );
      delete responseData.listData.Error;
   }

   return responseData;
}

function defineResponseDataError(error) {
   const responseData = {};

   responseData.hasError = true;
   responseData.errorMessage = error.message ? error.message : error;

   return responseData;
}

function getRequestState(commitState) {
   const requestState = store.getters.requests;

   if (requestState[commitState] === undefined)
      requestState[commitState] = { get: {}, send: {}, delete: {} };

   return requestState;
}

function translateErrorMessage(message) {
   const translations = {
      undefined: 'Ocorreu um problema ao obter os dados de retorno!',

      'connect ECONNREFUSED 127.0.0.1:2001':
         'Houve um problema ao tentar se conectar com o servidor, tente novamente!',

      'Invalid usage of the option NEXT in the FETCH statement.':
         'Erro inesperado ao realizar a consulta. Favor entre em contato com o suporte.',

      "An expression of non-boolean type specified in a context where a condition is expected, near 'AND'.":
         'Erro inesperado ao realizar a consulta. Favor entre em contato com o suporte.',
   };

   const translatedMessage = translations[message]
      ? translations[message]
      : message;

   return translatedMessage;
}
