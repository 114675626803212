var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PbButton", {
        staticStyle: {
          "flex-shrink": "0",
          margin: "0",
          "margin-left": "12px",
          "font-size": "20px !important",
        },
        attrs: {
          color: "primary",
          buttonStyle: "regular",
          icon: "far fa-bell",
          notificationBadgePosition: "top-right",
          "notification-badge-value": _vm.unreadItemsLength,
          notificationBadgeColor: "#FF7614",
        },
        nativeOn: {
          click: function ($event) {
            return (() =>
              (_vm.isNotificationModalOpen =
                !_vm.isNotificationModalOpen)).apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _vm.isNotificationModalOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeNotificationModal,
                  expression: "closeNotificationModal",
                },
              ],
              staticClass: "container-notification",
            },
            [
              _c(
                "div",
                { staticClass: "pb-row container-items-notification" },
                [
                  _c("div", { staticClass: "pb-col-12 header-notification" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pb-row",
                        staticStyle: { "align-items": "center" },
                      },
                      [
                        _c("div", { staticClass: "pb-col-4 title-header" }, [
                          _vm._v("Notificações"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pb-col-8" }, [
                          _c(
                            "div",
                            {
                              staticClass: "pb-row",
                              staticStyle: {
                                "justify-content": "end",
                                "margin-right": "16px",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pb-col-5" },
                                [
                                  _c(
                                    "PbButton",
                                    {
                                      staticClass: "button-formatted",
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        color: "gray",
                                        buttonStyle: "background",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showReadOrUnreadButton()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.showUnreadNotifications
                                              ? "Todos"
                                              : "Não lidos"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "pb-col-5" },
                                [
                                  _c(
                                    "PbButton",
                                    {
                                      staticClass: "button-formatted",
                                      attrs: {
                                        color: "gray",
                                        buttonStyle: "background",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.clearAll()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Limpar tudo\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pb-col-12 content-notification pb-scroll-primary",
                      staticStyle: { height: "300px", overflow: "auto" },
                    },
                    _vm._l(_vm.notification, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "pb-row content-items-notification",
                          style: `
        ${"background-color: #F5F6F7;"}
        ${
          item.active
            ? "font-weight: bolder; color: #292D32;"
            : "color: var(--color-gray-40);"
        }`,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pb-col-2 clickOnNotification",
                              on: {
                                click: function ($event) {
                                  return _vm.openNotification(item.link)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "pb-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pb-col-12 icon-item-notification",
                                  },
                                  [
                                    _c("PbIcon", {
                                      attrs: { icon: "far fa-envelope" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pb-col-8 clickOnNotification",
                              on: {
                                click: function ($event) {
                                  return _vm.openNotification(item.link)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pb-row content-text" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pb-col-12 limit-characters title-notification",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.title) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pb-col-12 limit-characters subtitle-notification",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.message) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !item.read
                            ? _c(
                                "div",
                                {
                                  staticClass: "pb-col-1 clickOnNotification",
                                  on: {
                                    click: function ($event) {
                                      return _vm.markNotificationAsRead(item.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "notification-button" },
                                    [
                                      _c("PbIcon", {
                                        attrs: { icon: "fas fa-eye-slash" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pb-col-1 clickOnNotification",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteSingleNotification(item.id)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "notification-button" },
                                [
                                  _c("PbIcon", {
                                    attrs: { icon: "fas fa-trash" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }